<template>
  <div class="sidebar__item">
 
                         
    <div id="myContent" class="sidebar__item--content" v-if="!isReadOnlyForm" >  
      <div class="sidebar__item-form">
        <label class="sidebar__item-form--label">Filtro
          <b-button variant="primary"  class="btn-blue" size="sm" @click="setIsReadOnly(true)">
          
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </b-button>
        </label>

      </div>

      <div class="sidebar__item-form">
        <label class="sidebar__item-form--label"> Fecha</label>
        <div class="sidebar__item-form--input">
          <flat-pickr              
            class="form-control mb-1"
            placeholder="Cuando..."              
            v-model="queryParams.dateRange"
            @input="setDateQueryParams"   
            :config="{ minDate: 'today', disable:[] , maxDate: '', disableMobile: true}"
          />
        </div>
      </div>
      <div class="sidebar__item-form">
        <div class="item__total-guests">                
          <span>No. Personas</span>                                                
          <button class="item__btn-guests" type="button" :disabled="true" > {{totalGuest}} </button>
        </div>  
        <div class="guests-pax">                
          <span  class="pax-type">Adultos</span>                  
          <div class="pax-qty">
              <button class="btn-qty" type="button" :disabled="queryParams.adults == 1"  @click="setPaxAdults('-')">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 7.5H14" stroke="#34303E"/>
                </svg>
              </button>
              <span> {{ queryParams.adults }} </span>
              <button class="btn-qty" type="button" @click="setPaxAdults('+')">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 1V14M1 7.5H14" stroke="#34303E"/>
                </svg>
              </button>
          </div>
        </div>      
        <div class="guests-pax">                
          <span  class="pax-type">Niños</span>                  
          <div class="pax-qty">
            <button class="btn-qty" type="button" :disabled="queryParams.children == 0" @click="setPaxChildren('-')">
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 7.5H14" stroke="#34303E"/>
                </svg>
            </button>
            <span> {{queryParams.children}} </span>
            <button class="btn-qty" type="button"  @click="setPaxChildren('+')"  >
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.5 1V14M1 7.5H14" stroke="#34303E"/>
                </svg>
            </button>
          </div>
        </div>           
      </div>
      <div class="sidebar__item-form">
        <div class="btn-search">
          <button class="button button--flex" @click="searchData" :disabled="isloadingProducts">        
                       
           Buscar
          </button>
        </div>                                                             
      </div>
    </div>

    <div id="myContent" class="sidebar__item--content " v-else>    
      <div class="sidebar__item-form">
        <label class="sidebar__item-form--label">Filtro
          <b-button variant="primary"  class="btn-blue" size="sm" @click="setIsReadOnly(false)">
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.5 9.5L0.146447 9.14645L0 9.29289V9.5H0.5ZM9.5 0.5L9.85355 0.146447C9.65829 -0.0488155 9.34171 -0.0488155 9.14645 0.146447L9.5 0.5ZM14.5 5.5L14.8536 5.85355C15.0488 5.65829 15.0488 5.34171 14.8536 5.14645L14.5 5.5ZM5.5 14.5V15H5.70711L5.85355 14.8536L5.5 14.5ZM0.5 14.5H0C0 14.7761 0.223858 15 0.5 15L0.5 14.5ZM0.853553 9.85355L9.85355 0.853553L9.14645 0.146447L0.146447 9.14645L0.853553 9.85355ZM9.14645 0.853553L14.1464 5.85355L14.8536 5.14645L9.85355 0.146447L9.14645 0.853553ZM14.1464 5.14645L5.14645 14.1464L5.85355 14.8536L14.8536 5.85355L14.1464 5.14645ZM5.5 14H0.5V15H5.5V14ZM1 14.5V9.5H0V14.5H1ZM6.14645 3.85355L11.1464 8.85355L11.8536 8.14645L6.85355 3.14645L6.14645 3.85355ZM8 15H15V14H8V15Z" fill="#ffffff"/>
            </svg> 
          </b-button>
        </label>
   

      </div>   
      <div class="sidebar__item-form">
        <label class="sidebar__item-form--label"> Fecha </label>
        <div class="sidebar__item-form--input">
          {{ queryParams.dateRange }}
          <!-- desde el mixin market -->
        </div>
      </div>
      <div class="sidebar__item-form">
        <div class="item__total-guests">        
   
          <span>No. Personas</span>                                                
          <button class="item__btn-guests" type="button" :disabled="true" > {{totalGuest}} </button>
        </div>  
        <div class="guests-pax">                
          <span  class="pax-type"> Adultos </span>                                          
          <span> {{ queryParams.adults }} </span>                      
        </div>      
        <div class="guests-pax">                
          <span  class="pax-type"> Niños</span>                                      
          <span> {{queryParams.children}} </span>                   
        </div>           
      </div>    
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'
  import { market } from '@/modules/shop/mixins/market'
  import flatPickr from 'vue-flatpickr-component'
  import vSelect from 'vue-select'
  import * as moment from 'moment'  

  export default {   
     mixins: [market],   
    components: {             
      vSelect,
      flatPickr                 
    }, 

    mounted(){
      this.queryParams.dateRange =moment(new Date()).format("YYYY-MM-DD")
    },

    data(){
      return{
        isReadOnlyForm: false,
      
   
      }
    },
    computed: {
    ...mapState('products',['isloadingProducts','selectedCategory']),
    ...mapState('start',['queryParams']),       
      totalGuest()  {
        return parseFloat(this.queryParams?.adults) + parseFloat(this.queryParams?.children)
      },


    },
    methods:{
      
    ...mapMutations('start',['setSubCategoryQueryParam','setNumberChildren','setNumberAdults','setDateInQueryParams']),     

          setIsReadOnly(boolean){        
        this.isReadOnlyForm = boolean 
        /*Si form no es de modo lectura modifico el css inline del elemento con id  myContent a un valor mas grande que el que tiene previamente 
         en modo read-only el form llega a los 276px, al hacer el swich a modo no-read-only ese heigh de 276 se queda corto, porque requiere minimo
         un heigh de 476px, por ende le pongo un valor a maxHeight que supere ese valor
         */
        if(!boolean){
          const myContent = document.querySelector('#myContent')
          if (myContent) {
            myContent.style.maxHeight = 500 + "px"
          }          
        }
      },
      setDateQueryParams(date){
        this.setDateInQueryParams(date)  
        
      },
       setPaxChildren(operator){
        const children = operator === '-' ? parseFloat( this.queryParams.children ) - 1 : parseFloat( this.queryParams.children) + 1
        this.setNumberChildren(children)
 
      },
      setPaxAdults(operator){
        const adults = operator === '-' ? parseFloat( this.queryParams.adults ) - 1 : parseFloat( this.queryParams.adults) + 1            
        this.setNumberAdults(adults)

      },

            async searchData(){
       
           this.$emit('sendQuote', this.selectedCategory)

      },
  },        
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/filter-search.scss";  
</style>