<template>
  <div class="mb-2">
    <div v-if="!isloadingProducts">
      <main class="grid">
        <article v-for="(product, index) in productos" :key="index" :class="bgCardArticle">
          <div>
            <ImgExperiences :product="product" :payloadParamsRates="payloadParamsRates"/>

            <!-- <div class="price-product"> Desde <span> ${{product.detail[0].saleprice}} USD</span> </div> -->
            <div class="text">
              <h2 class="name-product" :class="colorText" style="cursor: pointer" @click="showProduct(product)">{{product.name}}</h2>
              <div class="click-img-name" :class="colorText">Presiona la imagen para más información</div>
              <ExperiencesDetails  v-if="product.rates!=null" :experiencesDetails="product.detail" :product="product" />
              <ProductDetails v-if="product.rates==null" :productDetails="product.detail" :product="product" />
              <BtnViewInCart :product="product"/>
            </div>
          </div>
        </article>
      </main>

      <b-alert show variant="danger" v-if="!productos.length" class="mt-2">
        <div class="alert-body text-center">
          😣 <span>No hay productos</span>
        </div>
      </b-alert>
    </div>
    <div class="text-center" v-if="isloadingProducts">
      <b-spinner label="Loading..." variant="success" /><br />
      <strong>Cargando productos</strong>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ImgExperiences from "@/modules/shop/components/experiences/ImgExperiences";
import ProductDetails from "@/modules/shop/components/experiences/ProductDetails";
import ExperiencesDetails from "@/modules/shop/components/experiences/ExperiencesDetails";

import BtnViewInCart from "@/modules/shop/components/experiences/BtnViewInCart";

export default {
  props: {
    itemView: {
      type: String,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  components: {
    ImgExperiences,
    ExperiencesDetails,
    ProductDetails,
    BtnViewInCart,
  },
  data() {
    return {
      imgUrl: process.env.VUE_APP_IMG_SRC_API,
      imdDefault: require("@/assets/images/default.jpg"),
      // imdDefault: 'https://picsum.photos/seed/picsum/200/300'
    };
  },
  computed: {
    ...mapState("appConfig", ["layout"]),
    ...mapState("start", ["payloadParamsRates"]),
    ...mapState("shop", ["cart", "canalventa"]),
    ...mapState("products", ["filter", "isloadingProducts"]),
    ...mapGetters("products", ["filteredProducts"]),
    productos() {
      const items = this.filteredProducts;
      return items.slice(
        (this.filters.currentPage - 1) * this.filters.perPage,
        this.filters.currentPage * this.filters.perPage
      );
    },
    bgCardArticle() {
      return this.layout.skin === "dark" ? "article-dark" : "article-light";
    },
    colorText() {
      return this.layout.skin === "dark"
        ? "color-text-dark"
        : "color-text-light";
    },
    totalRows() {
      return this.filteredProducts.length;
    },
  },
  methods: {
    imgAlternativo(event) {
      event.target.src = this.imdDefault;
    },
    showProduct(product) {
      const { modelType , valueFromCategory} = product;
      if (modelType == "product"  ) {
        this.$router.push({
          name: "sales-product-details",
          params: { slug: "products-" + product.id },
        });
      }
    if (modelType == "rates") {
        this.$router.push({
          name: "experience-details",
          params: { slug: product.id +"_"+ this.payloadParamsRates.dateRange +'_'+ this.payloadParamsRates.adults +'_'+ this.payloadParamsRates.children },
        });
      }
      if (modelType == "event") {
        this.$router.push({
          name: "sales-product-details",
          params: { slug: "events-" + product.id },
        });
      }

      if (modelType == "package") {
        this.$router.push({
          name: "sales-product-details",
          params: { slug: "packages-" + product.id },
        });
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 20px;
  align-items: stretch;
}

.grid > article {
  position: relative;
  // border-radius: 8px;
  // height: 400px;
}
.article-dark {
  background: #283444;
  color: #fff;
}
.article-light {
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
  background: #fff;
}

.grid > article img {
  max-width: 100%;
  overflow: hidden;
}

.grid .text {
  padding: 20px;
}
.price-product {
  position: absolute;
  top: 20px;
  background-color: #f56960;
  font-size: 0.8rem;
  color: #ffffff;
  padding: 8px 16px;
}
.price-product span {
  font-size: 1.1rem;
  font-weight: 700;
  // color: #ffffff;
}
.name-product {
  font-size: 1rem;
  font-weight: 700;
  margin-block-end: 0.8rem;
}

.name-product:hover {
  text-decoration: underline;
}

.color-text-light {
  color: black;
}
.color-text-dark {
  color: white;
}

.click-img-name {
  // background-color: #ff9f43;
  // padding: .5rem;
  // color: #fff;
  margin-block-end: 0.5rem;
  font-size: 0.8rem;
  font-weight: 600;
}
</style>